.clients {
  margin: 8rem auto 4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1280px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    width: 85%;
    padding: 0;
    margin: 0 auto 4rem;
  }
  .part {
    width: 45%;
    &.one {
      height: 30rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .content {
        margin: 0 auto;
        width: 70%;
        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
      @media screen and (max-width: 600px) {
        width: 100%;
        text-align: center;
        .header {
          justify-content: center;
        }
      }
      .section-content {
        .subtitle {
          font-family: 'nimbus-sans';
          font-weight: bold;
          color: $white;
          font-size: 2rem;
          @media screen and (max-width: 600px) {
            font-size: 2.5rem;
          }
        }
        .text {
          width: 100%;
          font-family: 'nimbus-sans';
          font-weight: 300;
          font-size: 1.2rem;
          line-height: 37px;
          @media screen and (max-width: 600px) {
            width: 100%;
            font-size: 1rem;
          }
        }
      }
    }
    &.two {
      @media screen and (max-width: 600px) {
        margin-right: 0;
        width: 100%;
        margin-top: 0;
        display: flex;
        justify-content: center;
      }
    }
    .logos {
      width: 100%;
      height: 30rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .logo {
        width: 32%;
        height: 25%;
        background-repeat: no-repeat;
        background-position: center center;
        &.verizon {
          background-image: url('../assets/logos/verizon.png');
          background-size: 60%;
        }
        &.netflix {
          background-image: url('../assets/logos/netflix.png');
          background-size: 70%;
        }
        &.nike {
          background-image: url('../assets/logos/nike.png');
          background-size: 60%;
        }
        &.prime {
          background-image: url('../assets/logos/prime.png');
          background-size: 70%;
        }
        &.oreo {
          background-image: url('../assets/logos/oreo.png');
          background-size: 70%;
        }
        &.snapchat {
          background-image: url('../assets/logos/snapchat.png');
          background-size: 40%;
        }
        &.spotify {
          background-image: url('../assets/logos/spotify.png');
          background-size: 70%;
        }
        &.directv {
          background-image: url('../assets/logos/directv.png');
          background-size: 70%;
        }
        &.nhl {
          background-image: url('../assets/logos/nhl.png');
          background-size: 40%;
        }
        &.facebook {
          background-image: url('../assets/logos/facebook.png');
          background-size: 70%;
        }
        &.delta {
          background-image: url('../assets/logos/delta.png');
          background-size: 80%;
        }
        &.smirnoff {
          background-image: url('../assets/logos/smirnoff.png');
          background-size: 60%;
        }
      }
    }
  }
}