.about-content {
  width: 75%;
  margin: 4rem auto 5rem;
  max-width: 1280px;
  @media screen and (max-width: 600px) {
    margin: 2rem auto 0;
    width: 95%;
  }
  .about-section-one {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    justify-content: space-around;
    @media screen and (max-width: 600px) {
      flex-direction: column-reverse;
    }
    @media screen and (max-width: 1250px) {

    }
    .about-subsection {
      &.one {
        width: 23rem;
        height: 32rem;
        @media screen and (max-width: 600px) {
          width: 95%;
          margin: 2rem auto 1rem;
        }
        @media screen and (min-width: 601px) and (max-width: 1250px) {
          width: 14rem;
          height: 20rem;
        }
        .schwans {
          background: url('https://s3.amazonaws.com/mvrk2.0/schwans-min-1.png');
          background-size: cover;
          background-repeat: no-repeat;
          height: 100%;
          position: relative;
          z-index: 9;
          margin-top: 20px;
        }
      }
      &.two {
        width: 54%;
        @media screen and (max-width: 600px) {
          width: 95%;
          margin: 0 auto;
        }
        .agency-details {
          font-family: 'nimbus-sans';
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 37px;
          letter-spacing: -0.02em;
          p {
            margin-top: 0;
          }
        }
        .who-we-are {
          margin-top: 1.5rem;
          display: flex;
          justify-content: space-between;
          @media screen and (max-width: 600px) {
            flex-direction: column;
            width: 100%;
            margin: 0 auto;
          }
          .left {
            width: 40%;
            font-family: 'nimbus-sans';
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 25px;
            letter-spacing: -0.01em;
            @media screen and (max-width: 600px) {
              width: 100%;
            }
          }
          .right {
            margin-right: 4rem;
            @media screen and (max-width: 600px) {
              width: 100%;
              margin: 2rem auto 2rem;
            }
            .trait {
              flex-direction: column;
              align-items: flex-end;
              display: flex;
              font-family: 'anonymous-pro';
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 23px;
              letter-spacing: -0.01em;
              color: $blue;
              @media screen and (max-width: 600px) {
                align-items: flex-start;
              }
            }
            .trait:after {
              position: relative;
              background: url('../assets/strike.png');
              background-size: contain;
              background-repeat: no-repeat;
              content: '';
              left: 10px;
              top: 50%;
              right: 0px;
              z-index: 10;
              height: 1rem;
              margin-top: -13px;
              @media screen and (max-width: 600px) {
                left: -10px;
              }
            }
            .trad:after {
              width: 8rem;
            }
            .classic:after {
              width: 6rem;
            }
            .safe:after {
              width: 4rem;
            }
            .box:after {
              width: 10rem;
            }
          }
        }
      }
    }
  }
  .about-section-two {
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 600px) {
      margin-top: -1rem;
      display: flex;
      flex-direction: column;
    }
    .mobile-caption {
      display: none;
      margin-top: 2rem;
      .mobile-caption-line {
        display: inline-block;
        font-family: 'nimbus-sans';
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 36px;
        letter-spacing: -0.01em;
        color: $black;
        background: url('../assets/whiteTexture.jpeg') center center no-repeat;
        margin-bottom: 10px;
        padding: 0 .75rem;
      }
      .mobile-quote-attr {
        margin-top: 1rem;
        .name {
          font-family: 'nimbus-sans';
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: $blue;
        }
        .company, .title {
          font-family: 'anonymous-pro';
          font-style: normal;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.01em;
          color: $white;
        }
      }
      @media screen and (max-width: 600px) {
        display: block;
        width: 93%;
        margin: 0 auto;
      }
    }
    .photo {
      position: relative;
      z-index: 10;
      margin-top: -75px;
      width: 45rem;
      height: 30rem;
      background: url('https://s3.amazonaws.com/mvrk2.0/build-it-min-1.png') center center no-repeat;
      background-size: cover;
      @media screen and (max-width: 600px) {
        width: 95%;
        margin: 1rem auto 2rem;
        height: 250px;
      }
      @media screen and (min-width: 601px) and (max-width: 1250px) {
        width: 30rem;
        height: 20rem;
        margin-top: 1rem;
      }
      .caption {
        position: relative;
        left: -195px;
        top: 130px;
        max-width: 20rem;
        @media screen and (max-width: 600px) {
          display: none;
        }
        @media screen and (min-width: 601px) and (max-width: 1250px) {
          left: -350px;
          top: 30px;
        }
        .quote {
          font-family: 'nimbus-sans';
          font-style: normal;
          font-weight: bold;
          font-size: 45px;
          letter-spacing: 0.01em;
          position: relative;
          margin: 0 10px 0 -30px;
        }
        .caption-line {
          display: inline-block;
          font-family: 'nimbus-sans';
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 36px;
          letter-spacing: -0.01em;
          color: $black;
          background: url('../assets/whiteTexture.jpeg') center center no-repeat;
          background-size: cover;
          margin-bottom: 5px;
          padding: 0 .75rem;
        }
      }
      .quote-attr {
        margin-top: 2rem;
        .name {
          font-family: 'nimbus-sans';
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: $blue;
        }
        .company, .title {
          font-family: 'anonymous-pro';
          font-style: normal;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.01em;
          color: $white;
        }
      }
    }
    .dots-background {
      background-image: url('../assets/grid1.png');
      height: 300px;
      width: 400px;
      background-size: cover;
      position: absolute;
      z-index: -10;
      background-repeat: no-repeat;
      margin-top: -80px;
      left: 135px;
      @media screen and (max-width: 600px) {
        margin-left: -10px;
        margin-top: -335px;
        height: 250px;
        width: 250px;
      }
      @media screen and (min-width: 601px) and (max-width: 1250px) {
        margin-top: 90px;
        left: 450px;
      }
    }
  }
}



