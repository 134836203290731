.about-intro {
  width: 90%;
  max-width: 1280px;
  margin: 1rem auto 5rem;
  @media screen and (max-width: 600px) {
    margin: 0 auto 2rem;
  }
  .about-image1 {
    width: 70%;
    @media screen and (max-width: 600px) {
      width: 95%;
    }
    margin: 4rem auto 2rem;
    img {
      width: 100%;
    }
  }
  .subtitle-container {
    margin: 4rem auto 4rem;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media screen and (max-width: 600px) {
      width: 95%;
      flex-direction: column;
    }
    .subtitle {
      font-family: 'nimbus-sans';
      text-align: center;
      font-weight: bold;
      color: $white;
      font-size: 65px;
      text-transform: uppercase;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: 1px $white;
      @media screen and (max-width: 600px) {
        font-size: 2.5rem;
      }
    }
    .definition {
      @media screen and (max-width: 600px) {
        margin-top: 2rem;
      }
      img {
        height: 1rem;
      }
    }
  }
  .sub-heading {
      width: 60%;
      margin: 0 auto;
      @media screen and (max-width: 600px) {
        width: 90%;
        padding: 0;
      }
    .wrapper {
      width: 85%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      @media screen and (max-width: 600px) {
        width: 100%;
        margin: 0 auto;
      }
    }
    .noGlitch {
      margin: 0 auto;
      text-align: center;
      color: white;
      font-weight: 800;
      font-size: 25px;
      line-height: 28px;
      font-family: 'anonymous-pro';
      animation-name: jerk;
      animation-duration: 3s;
      animation-timing-function: linear;
      animation-delay: 5s;
      animation-iteration-count: infinite;
      animation-direction: reverse-alternate;
    }
    .glitch {
      text-align: center;
      color: white;
      font-weight: 800;
      position: relative;
      font-size: 25px;
      line-height: 28px;
      padding: 30px;
      width: 100%;
      font-family: 'anonymous-pro';
      animation-name: jerk;
      animation-duration: 3s;
      animation-timing-function: linear;
      animation-delay: 5s;
      animation-iteration-count: infinite;
      animation-direction: reverse-alternate;
    }
  }
}