.full-blurb {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 65%;
  margin: 0 auto;
  padding: 3rem;
  @media screen and (max-width: 600px) {
    width: 90%;
    padding: 0;
  }
  .wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    @media screen and (max-width: 600px) {
      width: 90%;
      margin: 0 auto;
    }
  }
  .noGlitch {
    margin: 0 auto;
    text-align: center;
    color: white;
    font-weight: 800;
    font-size: 18px;
    line-height: 20px;
    font-family: 'anonymous-pro';
    animation-name: jerk;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-delay: 5s;
    animation-iteration-count: infinite;
    animation-direction: reverse-alternate;
  }
  .glitch {
    text-align: center;
    color: white;
    font-weight: 800;
    position: relative;
    font-size: 25px;
    line-height: 28px;
    width: 100%;
    font-family: 'anonymous-pro';
    animation-name: jerk;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-delay: 5s;
    animation-iteration-count: infinite;
    animation-direction: reverse-alternate;
  }
  .plus-wrapper {
    text-align: center;
    &.top {
      margin-bottom: calc(2rem + 25px);
      @media screen and (max-width: 600px) {
        margin-bottom: 1rem;
      }
    }
    &.bottom {
      margin-top: 4rem;
      @media screen and (max-width: 600px) {
        margin-top: 2rem;
      }
    }
  }
}
