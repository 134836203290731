.bottom-banner {
  height: 5rem;
  @media screen and (max-width: 600px) {
    height: auto;
  }
  .background-dots {
    width: 100%;
    background: url('../assets/grid1.png');
    background-repeat: repeat;
    background-size: 450px 450px;
    opacity: 0.25;
    height: 21rem;
    position: absolute;
    z-index: -1;
    margin-top: -10rem;
    opacity: 0.4;
    @media screen and (max-width: 600px) {
      margin-top: -3rem;
      margin: 0 auto 0 -10px;
      width: 96vw;
    }
  }
  .cta {
    margin-top: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6rem;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      margin: 0 auto;
    }
    .cta-text {
      font-size: 55px;
      font-family: 'nimbus-sans';
      font-weight: bold;
      letter-spacing: -1px;
      text-align: center;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: 1px $white;
      margin-right: 3rem;
      @media screen and (max-width: 600px) {
        margin: 3rem auto 2rem;
        width: 90%;
        font-size: 55px;
      }
    }
  }
}