.about-who {
  width: 85%;
  margin: 4rem auto 0;
  max-width: 1280px;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 4rem auto 5rem;
  }
  .header-wrap {
    display: flex;
    justify-content: center;
  }
  .who-subtitle {
    font-family: 'nimbus-sans';
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 46px;
    text-align: center;
    letter-spacing: -0.01em;
    color: $white;
  }
  .who-sub-heading {
    width: 50%;
    margin: 3rem auto 2rem;
    font-family: 'nimbus-sans';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 37px;
    text-align: center;
    letter-spacing: -0.02em;
    color: $white;
    @media screen and (max-width: 600px) {
      width: 95%;
      text-align: left;
    }
  }
  .image-container {
    width: 70%;
    margin: 0 auto;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  .grid-wrapper {
    .text-image {
      display: none;
      position: absolute;
      z-index: 15;
      &.work-hard {
        margin-top: 585px;
        transform: rotate(-5deg);
        left: 20%;
        @media screen and (max-width: 600px) {
          margin-top: 300px;
          left: 1%;
        }
        img {
          height: 7rem;
          @media screen and (max-width: 600px) {
            height: 4rem;
          }
        }
      }
      &.relentless {
        right: 18%;
        margin-top: 400px;
        transform: rotate(5deg);
        @media screen and (max-width: 600px) {
          margin-top: 225px;
          right: 3%;
        }
        img {
          height: 7rem;
          @media screen and (max-width: 600px) {
            height: 4rem;
          }
        }
      }
      &.never-settle {
        left: 20%;
        margin-top: 1375px;
        @media screen and (max-width: 600px) {
          margin-top: 600px;
          left: 1%;
        }
        img {
          height: 8rem;
          @media screen and (max-width: 600px) {
            height: 5rem;
          }
        }
      }
    }
    .dots-background {
      background-image: url('../assets/grid1.png');
      height: 300px;
      width: 400px;
      background-size: cover;
      position: absolute;
      z-index: -10;
      background-repeat: no-repeat;
      &.one {
        right: 16%;
        margin-top: -25px;
      }
      &.two {
        left: 16.5%;
        margin-top: 850px;
      }
      &.three {
        margin-top: 1180px;
        right: 15%;
      }
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
    .about-who-image {
      width: 100%;
      &.tall {
        height: 100%;
        object-fit: cover;
        object-position: 50% 0;
        @media screen and (max-width: 600px) {
          height: 98.5%;
        }
      }
      &.spotify {
        height: 318px;
        object-fit: cover;
        @media screen and (max-width: 600px) {
          height: 150px;
        }
      }
      &.xmas {
        object-fit: contain;
        object-position: 50% 0; 
      }
    }
  }
}