.app {
  font-display: block;
  .home {
    overflow-x: hidden;
    margin: 0;
  }
  .about {
    width: 100%;
    margin: 0 auto;
  }
}
