.nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  .logo {
    padding-left: .5rem;
    svg {
      height: 3rem;
    }
  }
  .hidden {
    opacity: 0;
    transition: all 1s;
  }
}
.navMenuList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin-top: 35%;
  a, .fake-nav-link {
    .nav-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      .plus {
        margin-right: 1rem;
        display: block;
        svg {
          display: flex;
        }
      }
      .inactive-route {
        margin-left: calc(1rem + 13px);
      }
    }
    font-size: 2.5rem;
    text-decoration: none;
    font-family: 'nimbus-sans';
    color: $white;
    font-weight: bold;
    letter-spacing: -1px;
  }
  a:hover, .fake-nav-link:hover {
    color: $blue;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px $blue;
    transition: all 0.5s ease;
  }
  .nav-social {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 2rem;
    .social-text {
      color: $white;
      font-family: 'anonymous-pro';
    }
    .linkedin, .instagram {
      svg {
        color: $blue;
      }
    &:hover {
      svg {
        color: darken($blue, 20%);
      }
    }
    }
  }
}
.navMenuList a.active {
  color: $blue;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px $blue;
}
