.experience-modal {
  height: 100%;
  width: 100%;
  background-image: url('../assets/background.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow-y: scroll; 
  .modal-menu {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 1.5rem 24px 0 24px;
    .logo {
      padding-left: .5rem;
      svg {
        height: 3rem;
      }
    }
    .close {
      padding: .5rem 1rem;
      cursor: pointer;
    }
  }
  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: -2rem auto 2rem;
    @media screen and (max-width: 800px) {
      margin-top: 3rem;
    }
    .plus {
      height: 13px;
      margin-right: 1.25rem;
    }
    .number {
      font-family: 'anonymous-pro';
      font-weight: bold;
      color: $red;
      font-size: 21px;
      text-transform: uppercase;
      margin-right: 1.25rem;
    }
    .title {
      font-family: 'proxima-nova';
      font-weight: bold;
      color: $blue;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.24em;
    }
  }
  .modal-subtitle {
    font-family: 'nimbus-sans';
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 46px;
    text-align: center;
    letter-spacing: -0.01em;
  }
  .modal-body {
    @media screen and (max-width: 800px) {
      width: 100%;
      margin-top: 0;
    }
    .video {
      width: 70%;
      margin: 2rem auto;
      @media screen and (max-width: 800px) {
        width: 100%;
      }
      .video-page-link {
        text-align: center;
        margin-top: 1rem;
        color: $white;
        font-family: 'nimbus-sans';
        font-size: 16px;
        a {
          text-decoration: none;
          color: $blue;
        }
      }
    }
    .gallery-page-link {
      text-align: center;
      color: $white;
      font-family: 'nimbus-sans';
      font-size: 16px;
      a {
        text-decoration: none;
        color: $blue;
      }
    }
    width: 65%;
    margin: 2rem auto 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 800px) {
      flex-direction: row;
      justify-content: space-around;
    }
    .part {
      width: 90%;
      margin: 5rem auto 2rem;
      @media screen and (max-width: 800px) {
        &.two {
          margin: 5rem auto 5rem;
        }
      }
      &.one {
        @media screen and (max-width: 800px) {
          width: 100%;
          margin: 5rem auto 0;
        }
      }
      @media screen and (min-width: 800px) {
        width: 45%;
        padding: 0;
        margin: 1rem auto;
      }
      .gallery {
        @media screen and (max-width: 800px) {
          width: 100%;
        }
      }
      .sub-section {
        width: 100%;
        margin: 0 auto;
        @media (max-width: 520px) {
          .awssld__controls {
            visibility: visible;
          }
        }
        .awssld {
          --slider-height-percentage: 65%;
          --slider-transition-duration: 300ms;
          --organic-arrow-thickness: 4px;
          --organic-arrow-border-radius: 2px;
          --organic-arrow-height: 14px;
          --organic-arrow-color: #38F4F1;
          --control-button-width: 10%;
          --control-button-height: 10%;
          --control-button-background: transparent;
          --control-bullet-color: #FFFFFF;
          --control-bullet-active-color: #FFFFFF;
          --loader-bar-height: 5px;
          --loader-bar-color: #38F4F1;
          .awssld__bullets button {
            height: 8px;
            width: 8px;
          }
        }

        .modal-text {
          font-family: 'nimbus-sans';
          font-style: normal;
          font-weight: 300;
          font-size: 1.1rem;
          line-height: 170%;
          letter-spacing: -0.01em;
          &.one {
            margin-top: 0;
          }
        }
      }
    }
  }
}