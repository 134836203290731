@import url('https://use.typekit.net/vtt4tqq.css');
@import '~video-react/styles/scss/video-react';
@import 'variables';
@import 'app';
@import 'pages';
@import 'nav';
@import 'splash';
@import 'full-blurb';
@import 'manifesto';
@import 'clients';
@import 'experience';
@import 'experience-modal';
@import 'swiper-slide';
@import 'footer';
@import 'page-intro';
@import 'about-intro';
@import 'about-content';
@import 'about-who';
@import 'bottom-banner';
@import 'vx-form';

body {
  background: url('../assets/background.png') no-repeat center center;
  background-size: cover;
  background-color: #111111;
  color: #FFFFFF;
  min-height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.acceptButton::after {
  content: url(../assets/caret.svg);
  position: absolute;
  top: 52%;
  transform: translateY(-50%);
  margin-left: 1rem;
  @media screen and (max-width: 600px) {
    top: 81%;
  }
}

@font-face {
  font-display: swap;
  font-family: 'Verizon-DS-Bold';
  src: url('../assets/fonts/VerizonNHGeDS-Bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Verizon-DS-Regular';
  src: url('../assets/fonts/VerizonNHGeDS-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Verizon-TX-Bold';
  src: url('../assets/fonts/VerizonNHGeTX-Bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Verizon-TX-Regular';
  src: url('../assets/fonts/VerizonNHGeTX-Regular.ttf') format('truetype');
}

@keyframes jerk {
  10% {
    opacity: 0.8;
    top: -4px;
    left :0px;
    transform: scale(1, 1);
    transform: skew(0, 0);
    text-shadow: -2px 0 #F1122D;
  }
  12% {
    opacity: 0.8;
    top: 5px;
    left: -10px;
    transform: scale(1, 1.3);
    transform: skew(4deg, 0);
    text-shadow: -2px 0 #38F4F1;
  }
  14% {
    opacity: 0.8;
    top: -2px;
    left: 15px;
    transform: scale(1, 1.2);
    transform: skew(-5deg, 0);
    text-shadow: -2px 0 #F1122D;
  }
  16% {
    opacity: 0.8;
    top: 0px;
    left: 5px;
    transform: scale(1, 1.2);
    transform: skew(-6deg, 0);
    text-shadow: -2px 0 #38F4F1;
  }
  18% {
    opacity: 0.8;
    top: 0px;
    left: 12px;
    transform: scale(1, 1.2);
    transform: skew(-1.5deg, 0);
    text-shadow: -3px 0 #F1122D;
  }
  19% {
    opacity: 0.8;
    top: 0px;
    left: -4px;
    transform: scale(1, 1.2);
    transform: skew(-2deg, 0);
    text-shadow: -3px 0 #38F4F1;
  }
  20% {
    opacity: 0.3;
    top: -1px;
    left: 5px;
    transform: scale(1, 1.2);
    transform: skew(-1.5deg, 0);
    text-shadow: -3px 0 #F1122D;
  }
  21% {
    opacity: 0.8;
    top: 1px;
    left: -5px;
    transform: scale(1, 1);
    transform: skew(0, 0);
    text-shadow: -3px 0 #38F4F1;
  }
  25% {
    text-shadow: none;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes text-wipe {
  0% {
    opacity: 1;
    -webkit-mask-position: 100%;
    transform: translate3d(-25%, 0, 0);
    visibility: visible;
  }
  80% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-mask-position: 0%;
  }
}
