.rcc-Carousel {
  position: relative;
  user-select: none;
  & > div {
    display: flex;
    align-items: center;
  }
}

.rcc-List {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.rcc-ListItem {
  height: 100%;
}

.rcc-ListItems {
  white-space: nowrap;
  height: 100%;
}

.slider-arrow-prev {
  position: absolute;
  z-index: 10;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 45%;
  cursor: pointer;
  height: 4rem;
  padding: 2rem 0 2rem 2rem;
}
.slider-arrow-next {
  position: absolute;
  z-index: 10;
  margin: auto;
  top: 0;
  left: 45%;
  bottom: 0;
  right: 0%;
  cursor: pointer;
  height: 4rem;
  padding: 2rem 2rem 2rem 0;
}
@media screen and (max-width: 800px) {
  .slider-arrow-prev {
    position: absolute;
    z-index: 10;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 85%;
    cursor: pointer;
    height: 2rem;
    padding: 2rem 0 2rem;
  }
  .slider-arrow-next {
    position: absolute;
    z-index: 10;
    margin: auto;
    top: 0;
    left: 85%;
    bottom: 0;
    right: 0%;
    cursor: pointer;
    height: 2rem;
    padding: 2rem 0 2rem;
  }
}