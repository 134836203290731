.vx-form {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  color: black;
  min-height: 100vh;
  font-family: 'Verizon-DS-Regular';
  .text-link {
    text-decoration: none;
    color: black;
  }
  .header-container {
    border-bottom: 1px solid #D8DADA;
    padding: 50px 0;
    .header {
      display: flex;
      flex-direction: row;
      width: 100%;
      font-family: 'Verizon-DS-Bold';
      align-items: center;
      justify-content: space-between;
      width: 85%;
      margin: 0 auto;
      .logo-container {
        .form-logo {
          height: 50px;
        }
      }
      .contact-container {
        font-size: 20px;
        .spacer {
          margin: 0 .25rem;
        }
      }
    }
  }
  .form-container {
    width: 85%;
    margin: 65px auto 0;
    .intro {
      .intro-title {
        font-family: 'Verizon-DS-Bold';
        font-size: 48px;
      }
      .intro-text {
        margin-top: 3rem;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 20px;
      }
    }
    .form {
      .form-section-header {
        font-size: 24px;
        font-family: 'Verizon-DS-Bold';
        margin: 24px 0 12px;
      }
      input {
        width: 100%;
        font-size: 16px;
        padding: 0 12px;
        height: 44px;
      }
      .vz-label {
        font-size: 12px;
        font-family: 'Verizon-TX-Regular';
        margin-bottom: 4px;
      }
      .vz-text-input-container {
        .vz-text-input, .vz-phone {
          border: 1px solid #E5E5E5;
          border-bottom: 1px solid #000000;
        }
        margin-bottom: 24px;
      }
      .vz-textarea-container {
        .vz-textarea {
          height: 218px;
          width: 100%;
          resize: none;
          margin-bottom: 19px;
          font-family: 'Verizon-TX-Regular';
          padding: 12px;
          font-size: 16px;
          border: 1px solid #E5E5E5;
          border-bottom: 1px solid #000000;
        }
        .vz-label {
          font-size: 12px;
          font-family: 'Verizon-TX-Regular';
          margin-bottom: 4px;
        }
      }
      .vz-select {
        height: 44px;
        width: 100%;
        font-size: 16px;
        border: 1px solid #E5E5E5;
        border-bottom: 1px solid #000000;
        padding: 0 12px;
        margin-top: 4px;
        margin-bottom: 20px;
      }
      .vz-feedback {
        margin-top: -19px;
        color: #CD040B;
      }
      .form-submit-button {
        background-color: black;
        color: white;
        border-radius: 22px;
        width: 188px;
        font-size: 16px;
        font-family: 'Verizon-DS-Bold';
        margin: 48px 0 5rem;
        padding: .75rem;
        cursor: pointer;
        &.disabled {
          cursor: default;
          background: hsla(0, 0, 0, 0.7);
        }
      }
    }
    .thanks {
      margin: 0 auto;
      font-size: 48px;
      font-family: 'Verizon-DS-Bold';
    }
    .next-steps {
      .expect {
        font-size: 25px;
        font-family: 'Verizon-DS-Bold';
        letter-spacing: .5px;
        margin-bottom: 2rem;
      }
      .list {
        padding-left: 12px;
        li {
          line-height: 20px;
          font-size: 16px;
          font-family: 'Verizon-DS-Regular';
          margin-bottom: .75rem;
          letter-spacing: .5px;

        }
      }
    }
  }

}