.manifesto {
  margin: 4rem auto 4rem;
  display: flex;
  flex-direction: row-reverse;
  padding: 0 11rem;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1280px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    width: 85%;
    padding: 0;
    margin: 4rem auto 0;
  }
  @media screen and (min-width: 600px) and (max-width: 1375px) {
    flex-direction: row;
    padding: 1rem;
  }
  .part {
    width: 45%;
    @media screen and (min-width: 600px) and (max-width: 875px) {
      width: 75%;
    }
    &.one {
      @media screen and (max-width: 600px) {
        width: 100%;
        text-align: center;
        .header {
          justify-content: center;
        }
      }
      @media screen and (min-width: 600px) and (max-width: 1375px) {
        margin: 0 auto;
      }
      .section-content {
        .subtitle {
          font-family: 'nimbus-sans';
          font-weight: bold;
          color: $white;
          font-size: 3rem;
          text-transform: uppercase;
          margin-bottom: 2rem;
          -webkit-text-fill-color: transparent;
          -webkit-text-stroke: 1px $white;
          @media screen and (max-width: 600px) {
            font-size: 2.5rem;
          }
        }
        .text {
          font-family: 'nimbus-sans';
          font-weight: 300;
          font-size: 1.2rem;
          line-height: 37px;
        }
        .button-container {
          margin-top: 3rem;
          @media screen and (max-width: 600px) {
            margin-top: 2.5rem;
          }
        }
      }
    }
    &.two {
      margin-right: 6rem;
      @media screen and (max-width: 600px) {
        margin-right: 0;
        width: 100%;
        margin-top: 3rem;
        display: flex;
        justify-content: center;
      }
      @media screen and (min-width: 600px) and (max-width: 1375px) {
        width: 75%;
        margin: 5rem auto;
      }
      .photo {
        width: 450px;
        height: 525px;
        background-image: url('https://s3.amazonaws.com/mvrk2.0/yahoo-finance-vault-min.png');
        background-position: 48% 50%;
        background-repeat: no-repeat;
        background-size: 100%;
        @media screen and (max-width: 600px) {
          width: 300px;
          height: 350px;
          margin-top: 1rem;
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0;
          margin-inline-end: 0;
        }
        @media screen and (min-width: 600px) and (max-width: 1375px) {
          width: 350px;
          height: 425px;
          margin: 0 auto;
        }
        .caption {
          position: relative;
          left: -55px;
          top: 30px;
          @media screen and (max-width: 600px) {
            left: -15px;
            top: 25px;
          }
          .caption-line {
            display: inline-block;
            font-family: 'nimbus-sans';
            font-style: normal;
            font-weight: bold;
            font-size: 25px;
            line-height: 36px;
            letter-spacing: -0.01em;
            color: $black;
            background: url('../assets/whiteTexture.jpeg') center center no-repeat;
            background-size: cover;
            margin-bottom: 10px;
            padding: 0 .75rem;
            @media screen and (max-width: 600px) {
              font-size: 1rem;
              line-height: 1.5rem;
            }
          }
        }
      }
      .dots-background {
        background-image: url('../assets/grid1.png');
        height: 400px;
        width: 300px;
        background-size: contain;
        position: absolute;
        z-index: -1;
        background-repeat: no-repeat;
        margin-top: -250px;
        @media screen and (max-width: 600px) {
          margin-left: -70px;
          margin-top: 175px;
          height: 300px;
          width: 200px;
        }
      }
      .arrow {
        background: url('../assets/arrow.png') no-repeat center center;
        background-size: contain;
        height: 110px;
        width: 175px;
        position: absolute;
        transform: rotate(-2.29deg);
        margin-top: -475px;
        margin-left: 400px;
        @media screen and (max-width: 1375px) {
          display: none;
        }
      }
    }
  }
}
