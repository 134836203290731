.splash {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  margin: 0 auto;
  height: 100vh;
  .overlay {
    top: 0;
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 100;
    background: rgba(17, 17, 17, .35)
  }
  .background-video-container {
    position: absolute;
    width: 100%;
    min-width: 100vw;
    max-width: 100vw;
    max-height: 100vh;
    margin: -4.75rem auto 0;
    overflow: hidden;
    .bg-vid {
      height: 100vh;
      object-fit: fill;
      min-width: 100vw;

    }
    @media screen and (max-width: 600px) {
      display: flex;
      height: 100vh;
      .bg-vid {
        object-fit: cover;
      }
    }
  }
  // .video-container {
  //   &.playing {
  //     animation: fadeIn ease 1s;
  //     animation-fill-mode: forwards;
  //   }
  //   @media screen and (max-width: 600px) {
  //     display: none;
  //   }
  //   width: 80%;
  //   margin-top: 1rem;
  //   .video-react .video-react-big-play-button {
  //     display: none;
  //   }
  //   .video-react-video {
  //     &:focus {
  //       outline: none;
  //     }
  //   }
  // }
  .create-the-future {
    font-size: 8rem;
    font-family: 'nimbus-sans';
    font-weight: bold;
    letter-spacing: -5px;
    text-align: center;
    position: absolute;
    opacity: 1;
    z-index: 100;
    -webkit-mask-image: linear-gradient(to left, #0000 55%, #000 75%);
    -webkit-mask-size: 500%;
    animation-name: text-wipe;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    @media screen and (min-width: 600px) and (max-width: 900px) {
      top: 30%;
      font-size: 5rem;
      padding: 0 1rem;
    }
    top: 38%;
    // &.playing {
    //   animation: fadeOut ease 1s;
    //   animation-fill-mode: forwards;
    // }
  }
  // .watch-reel {
  //   position: absolute;
  //   @media screen and (min-width: 600px) and (max-width: 900px) {
  //     top: 55%;
  //   }
  //   top: 60%;
  //   &.playing {
  //     animation: fadeOut ease 1s;
  //     animation-fill-mode: forwards;
  //   }
  // }
  @media screen and (max-width: 600px) {
    .create-the-future {
      font-size: 4.75rem;
      line-height: 5rem;
      letter-spacing: -3px;
      position: absolute;
      top: 41%;
    }
    // .watch-reel {
    //   display: none;
    // }
  }
  .splash-footer {
    opacity: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 101;
    top: 93%;
    width: 95%;
    animation: fadeIn ease 7s;
    animation-iteration-count: 1;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    .left {
      img {
        height: 15px;
      }
      @media screen and (max-width: 600px) {
        img {
          height: 10px;
        }
      }
    }
    .right {
      img {
        height: 30px;
      }
      @media screen and (max-width: 600px) {
        img {
          height: 20px;
        }
      }
    }
  }
}

@supports (-webkit-text-stroke: 2px white) {
  .create-the-future {
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: 600px) {
      -webkit-text-stroke: 1px white;
    }
    @media screen and (min-width: 600px) {
      -webkit-text-stroke: 2px white;
    }
  }
}
